import * as React from "react"
import { IntlProvider } from "react-intl"
import { Locale, DEFAULT_LOCALE } from "./src/intl/locales"
import { frontendMessages } from "./src/intl/frontendMessages"
import { Location } from "@reach/router"
import { Paragraph } from "theme-ui"
import { navigate } from "gatsby"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { FormAlertProvider } from "./src/hocs/FormAlertProvider"

const queryClient = new QueryClient()

export const wrapRootElement = ({ element }) => {
  return (
    <Location>
      {({ location }) => {
        const userDefaultLocale = navigator.language.split("-")[0]
        console.log("LOCALE", userDefaultLocale)
        const localeInPath = Object.values(Locale).find(l =>
          location.pathname.includes(`/${l}`),
        )
        let locale = localeInPath
        if (localStorage.getItem("languageSelected")) {
          locale = localeInPath ? localeInPath : DEFAULT_LOCALE
        }

        // Redirect the user in case their default language is different from the default locale,
        // but they landed on the default locale page, and they have not explicitly set the language
        if (
          !localStorage.getItem("languageSelected") &&
          userDefaultLocale !== DEFAULT_LOCALE &&
          !localeInPath &&
          Object.values(Locale).includes(userDefaultLocale)
        ) {
          navigate(`/${userDefaultLocale}${location.pathname}`)
        }

        // If the user's current locale is not support, default to the default locale
        if (
          !localStorage.getItem("languageSelected") &&
          !Object.values(Locale).includes(userDefaultLocale)
        ) {
          locale = DEFAULT_LOCALE
        }

        return (
          <IntlProvider
            messages={frontendMessages[locale ? locale : userDefaultLocale]}
            locale={locale ? locale : userDefaultLocale}
            defaultLocale={userDefaultLocale}
            defaultRichTextElements={{
              p: chunks => <Paragraph>{chunks}</Paragraph>,
            }}
          >
            <QueryClientProvider client={queryClient}>
              <FormAlertProvider>{element}</FormAlertProvider>
            </QueryClientProvider>
          </IntlProvider>
        )
      }}
    </Location>
  )
}
