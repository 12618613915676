exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-template-jsx": () => import("./../../../src/templates/AboutPageTemplate.jsx" /* webpackChunkName: "component---src-templates-about-page-template-jsx" */),
  "component---src-templates-artwork-page-template-jsx": () => import("./../../../src/templates/ArtworkPageTemplate.jsx" /* webpackChunkName: "component---src-templates-artwork-page-template-jsx" */),
  "component---src-templates-book-page-template-jsx": () => import("./../../../src/templates/BookPageTemplate.jsx" /* webpackChunkName: "component---src-templates-book-page-template-jsx" */),
  "component---src-templates-home-page-template-jsx": () => import("./../../../src/templates/HomePageTemplate.jsx" /* webpackChunkName: "component---src-templates-home-page-template-jsx" */),
  "component---src-templates-legality-page-template-jsx": () => import("./../../../src/templates/LegalityPageTemplate.jsx" /* webpackChunkName: "component---src-templates-legality-page-template-jsx" */),
  "component---src-templates-project-page-template-jsx": () => import("./../../../src/templates/ProjectPageTemplate.jsx" /* webpackChunkName: "component---src-templates-project-page-template-jsx" */),
  "component---src-templates-projects-page-template-jsx": () => import("./../../../src/templates/ProjectsPageTemplate.jsx" /* webpackChunkName: "component---src-templates-projects-page-template-jsx" */),
  "component---src-templates-sketches-page-template-jsx": () => import("./../../../src/templates/SketchesPageTemplate.jsx" /* webpackChunkName: "component---src-templates-sketches-page-template-jsx" */)
}

