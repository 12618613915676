const basePageStyles = {
  container: {
    width: ["90%", "90%", "87.5%"],
    mx: "auto",
  },
  contentContainer: {
    maxWidth: ["80vw", "800px"],
    mx: "auto",
  },
}

const baseAlertStyles = {
  borderRadius: 5,
  zIndex: 1,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  position: "fixed",
  bottom: 4,
  left: "50%",
  transform: "translate(-50%)",
  transition: "all 0.2s ease-in",
}

const themeBreakpointWidths = {
  sm: "40em",
  md: "57em",
  lg: "65em",
}

module.exports = {
  basePageStyles,
  space: [0, 4, 8, 16, 24, 32, 40, 48, 64],
  sizes: [0, 4, 8, 16, 24, 32, 40, 48],
  fonts: {
    body: '"Open Sans", sans-serif',
    bodyZh: '"Noto Sans SC", sans-serif',
    link: '"Calligraffitti", cursive',
    linkZh: '"ZCOOL KuaiLe", cursive',
    label: '"Calligraffitti", cursive',
    labelZh: '"ZCOOL KuaiLe", cursive',
    heading: '"Calligraffitti", cursive',
    headingZh: '"ZCOOL KuaiLe", cursive',
    mono: "monospace",
  },
  fontSizes: [18, 20, 22, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 600,
    headingZh: 400,
    bold: 600,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  colors: {
    text: "#236776",
    background: "#ffffff",
    lightGray: "#efefff",
    primary: "#236776",
    secondary: "#cbebf2",
    muted: "#AF7561",
    error: "#FF8CA2",
    success: "#14bca9",
  },
  opacities: [0, 0.25, 0.33, 0.5, 0.66, 0.75, 0.8, 1],
  text: {
    default: {
      fontFamily: "body",
      fontSize: 1,
      fontWeight: "body",
      lineHeight: "body",
    },
    paragraph: {
      fontFamily: "body",
      fontSize: 1,
      fontWeight: "body",
      lineHeight: "body",
    },
    caption: {
      fontFamily: "body",
      textAlign: "center",
    },
    label: {
      fontFamily: "heading",
      fontSize: 2,
      fontWeight: "bold",
      "&:lang(zh)": {
        fontFamily: "linkZh",
        fontWeight: "normal",
      },
    },
    subtitle: {
      fontFamily: "heading",
      "&:lang(zh)": {
        fontFamily: "headingZh",
      },
      fontSize: 2,
    },
    subheading: {
      fontFamily: "body",
      fontSize: 2,
      fontWeight: "bold",
      lineHeight: "body",
      color: "muted",
    },
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      "&:lang(zh)": {
        fontWeight: "headingZh",
        fontFamily: "headingZh",
      },
      lineHeight: "heading",
      color: "primary",
    },
    selected: {
      textAlign: "center",
      textDecoration: "none",
      fontFamily: "link",
      fontWeight: "bold",
      color: "white",
      backgroundColor: "primary",
      transition: "all 0.2s ease-in",
      pt: 2,
      pb: 1,
      px: 4,
      borderRadius: "10px",
      "&:lang(zh)": {
        pt: 1,
        fontFamily: "linkZh",
        fontWeight: "body",
      },
    },
  },
  radii: ["0px", "2px", "4px", "8px", "16px"],
  borderWidths: [0, 1, 2, 3],
  breakpointWidths: { ...themeBreakpointWidths },
  breakpoints: [
    // Before the first breakpoint: Mobile portrait
    `@media (min-width: ${themeBreakpointWidths.sm}) and (orientation: landscape)`,
    // After the first breakpoint: Mobile landscape
    `@media (min-width: ${themeBreakpointWidths.sm}) and (orientation: portrait), (min-width: ${themeBreakpointWidths.md})`,
    // After the second breakpoint Tablet portrait and Small Tablet landscape
    themeBreakpointWidths.lg,
    // After the third breakpoint, large tablet landscape, desktops/TVs
  ],
  links: {
    card: {
      color: "primary",
      textDecoration: "none",
      transition: "all 0.2s ease-in",
      borderRadius: "10px",
      img: {
        borderRadius: "10px",
      },
      "&:hover": {
        color: "muted",
        opacity: 0.7,
      },
    },
    primary: {
      fontFamily: "link",
      color: "primary",
      textDecoration: "none",
      transition: "all 0.2s ease-in",
      "&:hover": {
        color: "muted",
      },
      "&:lang(zh)": {
        fontFamily: "linkZh",
      },
    },
    button: {
      textAlign: "center",
      textDecoration: "none",
      fontFamily: "link",
      fontWeight: "bold",
      color: "white",
      backgroundColor: "primary",
      transition: "all 0.2s ease-in",
      pt: 2,
      pb: 1,
      px: 4,
      borderRadius: "10px",
      cursor: "pointer",
      "&:hover": {
        color: "white",
        backgroundColor: "muted",
      },
      "&:lang(zh)": {
        pt: 1,
        fontFamily: "linkZh",
        fontWeight: "body",
      },
    },
  },
  buttons: {
    primary: {
      pb: "4px",
      borderRadius: "10px",
      fontSize: 4,
      fontFamily: "label",
      color: "white",
      bg: "primary",
      cursor: "pointer",
      "&:lang(zh)": {
        fontFamily: "labelZh",
        pb: "8px",
      },
    },
    transparent: {
      borderRadius: "10px",
      color: "primary",
      bg: "transparent",
      cursor: "pointer",
      fontSize: 2,
      fontFamily: "label",
      "&:lang(zh)": {
        fontFamily: "labelZh",
      },
    },
  },
  badges: {
    primary: {
      borderRadius: "10px",
      py: 1,
      px: 3,
      fontFamily: "label",
      color: "background",
      bg: "primary",
      "&:lang(zh)": {
        fontFamily: "labelZh",
        fontWeight: "body",
      },
    },
    white: {
      borderRadius: "10px",
      py: 1,
      px: 3,
      fontFamily: "label",
      color: "primary",
      bg: "white",
      width: "fit-content",
      "&:lang(zh)": {
        fontFamily: "labelZh",
        fontWeight: "body",
      },
    },
    outline: {
      borderRadius: "10px",
      fontFamily: "label",
      color: "primary",
      bg: "transparent",
      boxShadow: "inset 0 0 0 1px",
      "&:lang(zh)": {
        fontFamily: "labelZh",
      },
    },
  },
  forms: {
    input: {
      px: 3,
      my: 3,
      borderRadius: 5,
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      fontFamily: "body",
      fontSize: 1,
      "&::placeholder": {
        opacity: 0.9,
      },
    },
    checkbox: {
      height: 32,
      width: 32,
    },
  },
  alerts: {
    success: {
      ...baseAlertStyles,
      bg: "success",
      color: "white",
    },
    error: {
      ...baseAlertStyles,
      bg: "error",
      color: "white",
    },
  },
  styles: {
    root: {
      a: {
        color: "muted",
        transition: "all 0.2s ease-in",
        "&:hover": {
          color: "primary",
        },
        "&:lang(zh)": {
          fontFamily: "linkZh",
        },
      },
      p: {
        fontFamily: "body",
        fontWeight: "body",
        fontSize: 1,
        "&:lang(zh)": {
          fontFamily: "bodyZh",
        },
      },
      li: {
        fontFamily: "body",
        fontWeight: "body",
        fontSize: 1,
        "&:lang(zh)": {
          fontFamily: "bodyZh",
        },
      },
      h1: {
        variant: "text.heading",
        fontSize: 5,
        my: 4,
        "&:lang(zh)": {
          fontFamily: "headingZh",
        },
      },
      h2: {
        variant: "text.heading",
        fontSize: 4,
        my: 5,
        "&:lang(zh)": {
          fontFamily: "headingZh",
        },
      },
      h3: {
        variant: "text.heading",
        fontSize: 3,
        my: 4,
        "&:lang(zh)": {
          fontFamily: "headingZh",
        },
      },
      legend: {
        fontFamily: "body",
        fontWeight: "body",
        fontSize: 1,
        my: 2,
        "&:lang(zh)": {
          fontFamily: "bodyZh",
        },
      },
      fieldset: {
        borderRadius: 5,
      },
    },
  },
}
